
.notificationModalHeader {
    background-color: #fff;
    font-family: HM Ampersand;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 24px;
}
.notificationModalBody {
    height: 350px;
    overflow-x: auto;

    .notificationModalContainerTitle {
        font-family: HM Ampersand;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.25px;
        line-height: 20px;
    }

    .notificationModalContainerLabel {
        font-family: HM Ampersand;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0.4px;
        line-height: 14px;
    }

    .notificationModalContainerBody {
        font-family: HM Ampersand;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.25px;
        line-height: 16px;
    }

    .marginBottom{
        margin-bottom: 0px;
    }
}
