
    .loginLHSCss {
        width: 58%;
        height: 100%;
        position: fixed;
        z-index: 1;
    }
.loginRHSCss{
    width: "316px"!important;
    background: "#5870D1"!important;
}

.azureLoginModal {
    .azureModalTop{
        top:25%;
    }
}

.margin-0 {
    margin: 0;
}

.notificationIcon {
    font-family: HM Ampersand; 
    font-weight: 700
}

.image-item__btn-wrapper{
   position: absolute;
   top: -2px;
   right: 5px;
}

.fileUploadBtn{
    background-color: #f5f5f5;
    border-radius: 4px;
    position: absolute;
    left: 0px;
    min-height: 35px;
    min-width: 60%;
    padding: 10px 15px;
    float: left;
}

.dropDownOption{
    display: block;
    width: 100%;
    padding: 8px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin-top: 0.25rem !important;
    outline:0px;
}
.loginContactUsForm {
    .loginContactUsFormArrowIcon {
        font-family: HM Ampersand;
        font-size: 22px;
        font-weight: 400;
        line-height: 28px;
    }

    .loginContactUsFormWithLabel {
        font-family: HM Ampersand;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.1px;
    }

    .loginContactUsFormErrormsg {
        font-family: HM Ampersand;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.5px;
    }

    .upload-file-support {
        border-radius: 4px;
        text-align: center;
        margin-top: 5px;
        cursor: pointer;
        position: relative;
    }

    .loginContactUsFormAttachment {
        opacity: 0;
        float: left;
        cursor: pointer;
    }

    .loginContactUsFormAttachmentImage {
        position: absolute;
        top: 38%;
        left:44%;
    }
    .azureLoginModal {
        font-family: 'HM Ampersand';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 30px;
        margin-left: 7%;
    }
    .request_received_description {
        margin-left: 7%;
        margin-right: 15%;
        margin-bottom: 15px;
    }
    .bactToLoginMargin{
        margin-left: 25%;
    }
    .back_to_login_btn{
        background: #FFFFFF;
        border: 1px solid #5870D1;
        border-radius: 4px;
        color: #5870D1;
        padding: 6px 10px;
    }
}

.loginFAQuestion {
    .loginFAQuestionBackIcon {
        font-family: HM Ampersand;
        font-size: 22px;
        font-weight: 400;
        line-height: 28px;
    }

    .loginFAQuestionDownIcon {
        font-family: HM Ampersand;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
        cursor: pointer;
    }

    .loginFAQuestionPassword {
        font-family: HM Ampersand;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
    }

    .loginNeedHelp {
        float: right;
        margin-bottom: 20px;
    }
}

@media (max-width: 768px) {  
    .loginLHSCss {
        display: none;
    }
    .supImageCss, .hmBrandImageCss{
        width: inherit;
    }
  }
