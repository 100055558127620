// @import-normalize;
@import "bootstrap/dist/css/bootstrap.min.css";
@import "./variables.scss";

body,
html {
  height: 100%;
  width: 100%;
  min-height: 100%;
}

body {
    overflow: auto;
    display: grid;
    grid-template-rows: 1fr;
    background: $background-light-gray;
    font-size: $font-size-14;
    font-family: HM Ampersand;
}

#root {
  height: 100%;
  width: 100%;
}
